.employee-card {
    width:30%;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    margin: 10px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    display: block;
    border: 1px solid black;

  }
  @media only screen and (max-width: 1300px) {
    /* tab/mobile */
    .employee-card{
      width: 80%;
    }
  }

  .employee-card img {
    width: 200px;
    border-radius: 50%;
    margin-bottom: 10px;
  }

  .employee-card h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }

  .employee-card p {
    font-size: 1em;
    color: #666;
    margin-bottom: 5px;
  }
  h1,h2{
    align-self: flex-start;
  }
  .download-container{
    width: fit-content;
    height: fit-content;
    background-color: rgb(232, 232, 232);
    border-radius: 20px;
    border: 1px solid black;
    padding: 10px;
    margin: 20px auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  span{
    font-size: 150;
  }
  a{

    margin-top: 0;
    margin-bottom: 0;
  }


.attribute-container {

   padding-left: 5px;  
  /* Padding inside the container */
  display: flex; /* Ensure that content is flexibly positioned */
  max-width: 100%;
  margin: 10px 0; /* Optional margin for spacing */

}

.attribute-container h3 {
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
  font-size: 16px; /* Adjust font size to fit content */
  line-height: 1.5; /* Improve readability */
}



.attribute-text {
  font-size: x-large;
  color: red;
  max-width: 100%;
  text-align: left;
  /* Ensure text wraps within its container */
  overflow-wrap: break-word; /* Break long words onto the next line */
  word-wrap: break-word; /* For older browsers */
  hyphens: auto; /* Optional: adds hyphenation */
  white-space: normal; /* Ensure that the text will wrap normally */
  text-overflow: ellipsis; /* Optional: add an ellipsis (...) if text overflows */
  display: block; /* Ensure the block-level element takes up full width */
}
#save-contact-btn{
  width: 200px;
  height: 200px;
  border-radius: 20px;
}