.footer {
    /* position: absolute; */
    margin-top: 100px;
    bottom: 0px;
    left: 0;
    width: 100%;
    text-align: center;
    background-color: rgb(206, 206, 206);
    color: black;
    padding: 15px;
  }
  .social-logo{
    margin-left: 10px;
    margin-right: 10px;
  }
  .footer-social-icon{
    color: inherit;
  }
  #designed-by,#powered-by{
    font-size: x-small;
    color: #5f5f5f;;
  }
  .designer-name,.tech-name{
    font-style: italic;
    text-decoration: none;
  }
  .atag-by{
    color: inherit;
  }