.header {
 /* position: absolute; */
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    background-color: rgb(206, 206, 206);
    color: black;
    padding: 15px;
  }

  a.appTitle, a.appTitle:visited, a.appTitle:hover, a.appTitle:active {
    color: inherit;
    text-decoration: none;
  }
  a.appSubTitle, a.appSubTitle:visited, a.appSubTitle:hover, a.appSubTitle:active {
    color: inherit;
    text-decoration: none;
  }
  .appTitleTxt{
    margin-bottom: 0;
  }
  .appSubTitleTxt{
    margin-top: 0;
  }