:root{
  background-color: rgb(243, 243, 243);
 
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Basic body styling */
body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  /* padding: 5px; */
  max-width: 100%;
  overflow-x: hidden;
}

/* Container to hold content with some padding */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 5px;
}

h1,h2,h3,h4,h5,h6{
  margin-top: 10px;
  margin-bottom: 10px;
}
/* Make images responsive */
img {
  max-width: 100%;
  height: auto;
}


/* Flexbox layout for header and main content */
.header, .main {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Responsive grid layout */
.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

@media (min-width: 600px) {
  .grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 900px) {
  .grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

/* Media queries for different screen sizes */
@media (max-width: 599px) {
  /* Mobile styles */
  body {
     padding: 1px; 
  }

  .container {
    padding: 10px;
  }
}

@media (min-width: 600px) and (max-width: 899px) {
  /* Tablet styles */
  body {
     padding: 1px; 
  }

  .container {
    padding: 15px;
  }
}

@media (min-width: 900px) {
  /* Desktop styles */
  body {
     padding: 1px; 
  }

  .container {
    padding: 20px;
  }
}
html, body {
  overflow-x: hidden;
  overscroll-behavior: none;
  
}
body {
  position: relative
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.login-label{
  display: block;
  font-weight:bold;
  width:400px ;
  text-align:center;
  margin: 0 auto;

}
.profile-label{
  display: block; 
  font-weight:bold;
  width:150px ;
  text-align:center;
  margin: 0 auto;

}
.navLink{
margin: 10px;
display: inline-block;
}
.profileInputBlock{
  margin-top: 30px;
  margin-bottom: 30px;
  width: fit-content;
  margin: 20px auto;
  /* display: flex;
  justify-content: center;
  align-items: center; */

}
input,textarea{
  min-width: 300px; 
  max-width: 500px;
  width: 90%;
  text-align: center;
 display: block;
 margin: 0px auto 20px auto;
 font-size: 25px;
 padding: 10px;

}
input[type="file"]{
  border: 1px solid rgb(167, 167, 167);
}
textarea{
  height: 100px;
}
/* @media (min-width:900px){
  input{
    width: 500px;
  }
  textarea {
    width: 80%;
    height: 50px;
  };
} */

/* input#email{
  width: 6%;
} */

button{
  font-size: large;
}
