.choose-file-button,.delete-file-button{
    display:block;
    margin: 12px auto 0 auto;
    width: fit-content;
    
}
.delete-file-button{
    color: red;
}
img{
    display: block;
    margin: 0 auto;
  
}
.upload-filename{
    display: inline;
}
.profileInputBlock{
    width: 100%;
}
.upload-max-reminder{
    font-weight: bold;
}